import { useLocalization } from "@hooks/localization";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import NoSsr from "@material-ui/core/NoSsr";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect, useState } from "react";
import styles from "./CollapseBox.module.scss";

export default function CollapseBox(props: {
	title: string,
	text: string | JSX.Element,
	checkIcon?: boolean,
	note?: string | JSX.Element,
	index?: number,
	visibleOnScroll?: boolean | undefined,
	style?: React.CSSProperties
}): JSX.Element {

	const [isOpen, setIsOpen] = useState(false);
	const t = useLocalization();


	return <Box className={styles.plusCollapseContainer} style={props.style}>
		<Box className={styles.titleContainer} onClick={() => setIsOpen((prev) => !prev)}>
			<Typography className={styles.title}>
				{props.checkIcon &&
					<span className={styles.checkmarkIcon}>
						<Checkmark index={props.index} isContainerVisible={props.visibleOnScroll} />
					</span>}
				<span style={{ textAlign: "left" }}>{props.title}</span>
			</Typography>
			<NoSsr>
				<Box
					className={styles.arrow}
					role="button"
					aria-pressed="false"
					title={isOpen ? t("See_Less_Text") : t("See_More_Text")}
					aria-label={isOpen ? t("See_Less_Text") : t("See_More_Text")}>
					<ExpandMoreIcon className={`${styles.arrowIcon} ${isOpen && styles.closed}`} />
				</Box>
			</NoSsr>
		</Box>
		<Collapse in={isOpen}>
			<Box className={styles.text}>
				<Typography>{props.text}</Typography>
				{props.note && <Typography style={{ fontSize: "12px", marginTop: "16px" }}>{props.note}</Typography>}
			</Box>
		</Collapse>
	</Box>
};

function Checkmark(props: {
	isContainerVisible?: boolean,
	index?: number
}) {
	const [isShowing, setIsShowing] = useState(false);

	useEffect(() => {
		if (props.isContainerVisible === undefined) {
			setIsShowing(true);
		}

		if (isShowing) {
			return;
		}

		const timedelay = props.index ? (props.index * 200) : 0;

		if (props.isContainerVisible) {
			setTimeout(() => {
				setIsShowing(true);
			}, timedelay);
		}
	}, [props.isContainerVisible]);

	return <span className={styles.checkmarkContainer}>
		{isShowing &&
			<svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
				<circle cx="26" cy="26" r="25" fill="none" />
				<path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
			</svg>
		}
	</span>
}